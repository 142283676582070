import React, { useState } from "react";
import "./style.scss";
import { buildsService } from "../../_services/builds.service";

import PopupMedium from "../../widgets/Popups/popup-medium";
import moment from "moment";
import BadgeComp from "../../widgets/Badges/BadgeComp";
import CHOICES from "../../_helpers/CHOICES";
import { ERROR_MESSAGES } from "../../constants/app-constants";

const Viewdetails = (props) => {
  const [showPopup, setShowPopup] = useState(false);
  const [showReports, setShowReports] = useState(null);
  const [testBuilds, setTestBuilds] = useState(null);

  const [error, setError] = useState({
    faildToLoad: false,
    timeExceedError: false,
  });

  function toLocalDateTime(dateStr) {
    const utcDateStr = new Date(dateStr)
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).format(utcDateStr)
  }

  React.useEffect(() => {
    showChange(true);
    buildsService.getTestBuilds(props.appkey).then((testbuildResp) => {
      if (testbuildResp?.status == 200 && testbuildResp?.data) {
        const applicationList = testbuildResp?.data?.map((d) => ({
          id: d.id,
          name: d.name,
          started: moment.utc(d.created_at).format("MMM DD, YYYY HH:mm:ss"),
          builds_completed: d.status,
          builds_successful: d.result,
          reports: d.reports,
          createdAt: moment.utc(d.created_at).format("MMM DD, YYYY HH:mm:ss"),
          startedAt: moment.utc(d.started_at).format("MMM DD, YYYY HH:mm:ss"),
          completedAt: moment.utc(d.completed_at).format("MMM DD, YYYY HH:mm:ss"),
          status_message: d.status_message,
        }));

        setTestBuilds(applicationList);
        showChange(false);
        setError({
          ...error,
          faildToLoad: false,
          timeExceedError: false,
        });
      } else if (testbuildResp?.status == 408) {
        setError({
          ...error,
          timeExceedError: true,
        });
        showChange(false);
      } else {
        setError({
          ...error,
          faildToLoad: true,
        });
        showChange(false);
      }
    });
  }, [props.appkey, props.isRefreshBuilds]);

  function showChange(param) {
    props?.onChange(param);
  }

  function togglePopup(event) {
    setShowReports(event);
    setShowPopup(!showPopup);
  }

  function timeDifference(startStr, endStr) {
    if (startStr == 'Invalid date' || endStr == 'Invalid date') return null

    const startTime = new Date(startStr);
    const endTime = new Date(endStr);

    const timeDifferenceMs: number = endTime.getTime() - startTime.getTime();
    const hours: number = Math.floor(timeDifferenceMs / (1000 * 60 * 60));
    const minutes: number = Math.floor((timeDifferenceMs % (1000 * 60 * 60)) / (1000 * 60));
    const seconds: number = Math.floor((timeDifferenceMs % (1000 * 60)) / 1000);

    let result = '';
    if (hours > 0) result += `${hours} hr${hours > 1 ? 's' : ''} `;
    if (minutes > 0) result += `${minutes} min${minutes > 1 ? 's' : ''} `;
    if (seconds > 0) result += `${seconds} sec${seconds > 1 ? 's' : ''}`;

    return result.trim() || '0 secs';
  }

  return (
    <>
      {showPopup ? (
        <PopupMedium
          title="Test Reports"
          text="Close Me"
          closePopup={togglePopup}
          testReport={showReports}
        />
      ) : null}

      <div className="table-responsive table-custom">
        <table className="table table-striped data-table">
          <thead>
            <tr className="table-primary">
              <th>Test Build Id</th>
              <th>Name</th>
              <th>Started On</th>
              <th>Status / Result</th>
              <th>Status Message</th>
              <th>Execution Start Time</th>
              <th>Execution Time</th>
              <th>Reports</th>
            </tr>
          </thead>

          <tbody>
            {testBuilds && testBuilds.length > 0 ? (
              testBuilds.map((appone, index) => (
                <tr key={index}>
                  <td>{appone.id}</td>
                  <td> {appone.name} </td>
                  <td>{appone.started}</td>
                  <td className="align-middle">
                    <div className="d-flex result-capsule">
                      {appone?.builds_completed ===
                        CHOICES.BuildStatus.IN_PROGRESS && (
                          <BadgeComp
                            badgePosition={"left"}
                            badgeType={"bg-warning text-dark left"}
                            label={CHOICES.BuildStatus.CHOICE_MAP.InProgress}
                          />
                        )}
                      {appone?.builds_completed ===
                        CHOICES.BuildStatus.ERROR && (
                          <BadgeComp
                            badgePosition={"left"}
                            badgeType={"bg-danger left"}
                            label={CHOICES.BuildStatus.CHOICE_MAP.Error}
                          />
                        )}
                      {appone?.builds_completed ===
                        CHOICES.BuildStatus.REQUESTED && (
                          <BadgeComp
                            badgePosition={"left"}
                            badgeType={"bg-info text-dark left"}
                            label={CHOICES.BuildStatus.CHOICE_MAP.Requested}
                          />
                        )}
                      {appone?.builds_completed ===
                        CHOICES.BuildStatus.SUCCEEDED && (
                          <BadgeComp
                            badgePosition={"left"}
                            badgeType={"bg-success left"}
                            label={CHOICES.BuildStatus.CHOICE_MAP.Succeeded}
                          />
                        )}

                      {appone?.builds_completed !==
                        CHOICES.BuildStatus.IN_PROGRESS &&
                        appone?.builds_completed !==
                        CHOICES.BuildStatus.ERROR &&
                        appone?.builds_completed !==
                        CHOICES.BuildStatus.REQUESTED &&
                        appone?.builds_completed !==
                        CHOICES.BuildStatus.SUCCEEDED && (
                          <BadgeComp
                            badgePosition={"left"}
                            badgeType={"bg-warning text-dark left"}
                            label={"Unknown"}
                          />
                        )}

                      {appone?.builds_successful ===
                        CHOICES.BuildResult.FAILURE && (
                          <BadgeComp
                            badgePosition={"left"}
                            badgeType={"bg-danger right"}
                            label={CHOICES.BuildResult.CHOICE_MAP.Failure}
                          />
                        )}
                      {appone?.builds_successful ===
                        CHOICES.BuildResult.SUCCESS && (
                          <BadgeComp
                            badgePosition={"left"}
                            badgeType={"bg-success right"}
                            label={CHOICES.BuildResult.CHOICE_MAP.Success}
                          />
                        )}
                      {appone?.builds_successful ===
                        CHOICES.BuildResult.UNKNOWN && (
                          <BadgeComp
                            badgePosition={"left"}
                            badgeType={"bg-secondary right"}
                            label={CHOICES.BuildResult.CHOICE_MAP.Unknown}
                          />
                        )}

                      {appone?.builds_successful !==
                        CHOICES.BuildResult.FAILURE &&
                        appone?.builds_successful !==
                        CHOICES.BuildResult.SUCCESS &&
                        appone?.builds_successful !==
                        CHOICES.BuildResult.UNKNOWN && (
                          <BadgeComp
                            badgePosition={"left"}
                            badgeType={"bg-secondary right"}
                            label={CHOICES.BuildResult.CHOICE_MAP.Unknown}
                          />
                        )}
                    </div>
                  </td>
                  <td>{appone.status_message}</td>
                  <td>{toLocalDateTime(appone.startedAt)}</td>
                  <td>{timeDifference(appone.startedAt, appone.completedAt)}</td>
                  <td>
                    <a
                      className="link"
                      onClick={() => togglePopup(appone.reports)}
                    >
                      View Reports
                    </a>
                  </td>
                  {/* {appone.reports ? <a  onClick={() => togglePopup(appone.reports)}>View Reports</a> : ''} */}
                </tr>
              ))
            ) : error.timeExceedError ? (
              <tr>
                <td colSpan={10} className="text-center text-danger ">
                  {ERROR_MESSAGES.timeExceedError}
                </td>
              </tr>
            ) : error.faildToLoad ? (
              <tr>
                <td colSpan={10} className="text-center text-danger ">
                  {ERROR_MESSAGES.failedLoadRecordsError}
                </td>
              </tr>
            ) : (
              <tr>
                <td colSpan={10} className="text-center">
                  {ERROR_MESSAGES.noRecordsFound}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Viewdetails;